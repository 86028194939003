import React from 'react';
import Drawer from 'rsuite/Drawer';
import { currencyFormater } from 'utils';
import Link from 'next/link';
import { IoMdLogOut } from 'react-icons/io';
import { useRouter } from 'next/router';
import styles from './AccountDrawer.module.css';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from 'redux/actions/userActions';

const navLinks = {
    BET_HISTORY: '/bet-history',
    PROFILE: '/profile',
    WITHDRAWAL_REQUEST: '/withdrawal-requests',
    TRANSACTION_HISTORY: '/transaction-history',
    DEPOSIT: '/deposit',
    WITHDRAW: '/withdraw',
    SHARE_FUNDS: '/sharefunds',
    SHAREDFUNDS_HISTORY: '/sharedfunds-history',
    BOOKED_CODES: '/booked-codes',
    RECIEVED_OVERDRAFT: '/recieved-overdraft',
    SENT_OVERDRAFT: '/sent-overdraft',
};
const AccountDrawer = ({ open, handleClose }) => {
    const { userData, walletInformation } = useSelector((state) => state.userData);
    const dispatch = useDispatch();
    const router = useRouter();

    const handleLogOutUser = () => {
        dispatch(logOutUser());
        handleClose();
        router.push('/');
    };
    const handleRoutePage = (path) => {
        return router.push(path);
    };
    return (
        <Drawer open={open} onClose={handleClose} className={styles.drawerContainer} size="xs">
            <Drawer.Header style={{ paddingLeft: '50px', paddingRight: '10px' }}>
                <Drawer.Title>My Acount</Drawer.Title>{' '}
                <div className={styles.btnContainer} onClick={() => handleClose()}>
                    <AiOutlineClose className={styles.btnClose} />
                </div>{' '}
            </Drawer.Header>
            <Drawer.Body style={{ padding: '5px', paddingLeft: '30px' }}>
                <ul className={styles.drawerUl}>
                    <li className={styles.drawerLi}>
                        Wallet Bal:&nbsp; &#8358;
                        {currencyFormater(walletInformation?.walletBalance)}
                    </li>
                    <li className={styles.drawerLi}>
                        Commission Bal:&nbsp; &#8358;
                        {currencyFormater(walletInformation?.commissionBalance)}
                    </li>
                    {!userData?.isAgent && (
                        <li className={styles.drawerLi}>
                            Bonus Bal:&nbsp; &#8358;
                            {currencyFormater(walletInformation?.bonusBalance)}
                        </li>
                    )}

                    <li
                        className={
                            router.asPath === navLinks.PROFILE
                                ? styles.drawerLiActive
                                : styles.drawerLi
                        }
                    >
                        <div
                            onClick={() => handleRoutePage(navLinks.PROFILE)}
                            className={styles.drawerLink}
                        >
                            My Profile
                        </div>
                    </li>
                    {!userData?.isAgent && (
                        <li
                            className={
                                router.asPath === navLinks.SHARE_FUNDS
                                    ? styles.drawerLiActive
                                    : styles.drawerLi
                            }
                        >
                            <div
                                onClick={() => handleRoutePage(navLinks.SHARE_FUNDS)}
                                className={styles.drawerLink}
                            >
                                Share Funds
                            </div>
                        </li>
                    )}
                    <li
                        className={
                            router.asPath === navLinks.TRANSACTION_HISTORY
                                ? styles.drawerLiActive
                                : styles.drawerLi
                        }
                    >
                        <div
                            onClick={() => handleRoutePage(navLinks.TRANSACTION_HISTORY)}
                            className={styles.drawerLink}
                        >
                            Transaction History
                        </div>
                    </li>
                    {!userData?.isAgent && (
                        <li
                            className={
                                router.asPath === navLinks.SHAREDFUNDS_HISTORY
                                    ? styles.drawerLiActive
                                    : styles.drawerLi
                            }
                        >
                            <div
                                onClick={() => handleRoutePage(navLinks.SHAREDFUNDS_HISTORY)}
                                className={styles.drawerLink}
                            >
                                Shared Funds History
                            </div>
                        </li>
                    )}
                    <li
                        className={
                            router.asPath === navLinks.WITHDRAWAL_REQUEST
                                ? styles.drawerLiActive
                                : styles.drawerLi
                        }
                    >
                        <div
                            onClick={() => handleRoutePage(navLinks.WITHDRAWAL_REQUEST)}
                            className={styles.drawerLink}
                        >
                            Withdrawal Requests
                        </div>
                    </li>
                    <li
                        className={
                            router.asPath === navLinks.BET_HISTORY
                                ? styles.drawerLiActive
                                : styles.drawerLi
                        }
                    >
                        <div
                            onClick={() => handleRoutePage(navLinks.BET_HISTORY)}
                            className={styles.drawerLink}
                        >
                            Bet History
                        </div>
                    </li>
                    {!userData?.isAgent && (
                        <li
                            className={
                                router.asPath === navLinks.BOOKED_CODES
                                    ? styles.drawerLiActive
                                    : styles.drawerLi
                            }
                        >
                            <div
                                onClick={() => handleRoutePage(navLinks.BOOKED_CODES)}
                                className={styles.drawerLink}
                            >
                                Booked Codes
                            </div>
                        </li>
                    )}
                    <li
                        className={
                            router.asPath === navLinks.DEPOSIT
                                ? styles.drawerLiActive
                                : styles.drawerLi
                        }
                    >
                        <div
                            onClick={() => handleRoutePage(navLinks.DEPOSIT)}
                            className={styles.drawerLink}
                        >
                            Deposit
                        </div>
                    </li>
                    <li
                        className={
                            router.asPath === navLinks.WITHDRAW
                                ? styles.drawerLiActive
                                : styles.drawerLi
                        }
                    >
                        <div
                            onClick={() => handleRoutePage(navLinks.WITHDRAW)}
                            className={styles.drawerLink}
                        >
                            Withdraw
                        </div>
                    </li>

                    {userData?.isAgent && (
                        <li
                            className={
                                router.asPath === navLinks.RECIEVED_OVERDRAFT
                                    ? styles.drawerLiActive
                                    : styles.drawerLi
                            }
                        >
                            <div
                                onClick={() => handleRoutePage(navLinks.RECIEVED_OVERDRAFT)}
                                className={styles.drawerLink}
                            >
                                Recieved Overdraft
                            </div>
                        </li>
                    )}
                    {userData?.isAgent && walletInformation?.role !== 'cashier' && (
                        <li
                            className={
                                router.asPath === navLinks.SENT_OVERDRAFT
                                    ? styles.drawerLiActive
                                    : styles.drawerLi
                            }
                        >
                            <div
                                onClick={() => handleRoutePage(navLinks.SENT_OVERDRAFT)}
                                className={styles.drawerLink}
                            >
                                Sent Overdraft
                            </div>
                        </li>
                    )}

                    <li className={styles.drawerLiLogout} onClick={() => handleLogOutUser()}>
                        <IoMdLogOut /> &nbsp;Logout
                    </li>
                </ul>
            </Drawer.Body>
        </Drawer>
    );
};

export default AccountDrawer;
