// @flow
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiFillFacebook as AiFillFacebookIcon, AiOutlineHome } from 'react-icons/ai';
import { IoArrowRedoSharp, IoLibraryOutline } from 'react-icons/io5';
import {
    FaTwitter as FaTwitterIcon,
    FaInstagramSquare as FaInstagramSquareIcon,
} from 'react-icons/fa';
import { Icon } from '@rsuite/icons';
import Image from 'next/image';
import { MdAccountCircle, MdLibraryBooks } from 'react-icons/md';
import { useRouter } from 'next/router';
import styles from 'public/styles/Footer.module.css';
import AccountDrawer from './AccountDrawer';

export default function Footer() {
    const { showFullTVScreen } = useSelector((state) => state?.UI);
    const { userData } = useSelector((state) => state?.userData);
    const [openAccountDrawer, setOpenAccountDrawer] = useState(false);

    const router = useRouter();
    const handleClose = () => setOpenAccountDrawer(false);
    return showFullTVScreen ? null : (
        <footer className={styles.footer}>
            <AccountDrawer open={openAccountDrawer} handleClose={handleClose} />
            <div className={styles.topSection}>
                <section>
                    <div style={{ color: '#14B151', fontSize: '24px' }}> Our Lotto</div>
                    <div className={styles.appLink}> Home</div>
                    <div className={styles.appLink}> Contact Us</div>
                    <div className={styles.appLink}> Become an Agent</div>
                    <div className={styles.appLink}> About Us</div>
                    <div className={styles.appLink}> Web Affiliate</div>
                </section>

                <section style={{ justifyContent: 'center', margin: '1% 0' }}>
                    <div style={{ textAlign: 'center', marginBottom: '5px' }}>
                        <Image
                            src="/images/logo1.jpg"
                            style={{ objectFit: 'contain' }}
                            width={60}
                            height={50}
                        />
                    </div>
                    <ul className={styles.iconUl}>
                        <li className={styles.iconLi}>
                            <Icon as={AiFillFacebookIcon} size="2em" />
                        </li>
                        <li className={styles.iconLi}>
                            <Icon as={FaTwitterIcon} size="2em" />
                        </li>
                        <li className={styles.iconLi}>
                            <Icon as={FaInstagramSquareIcon} size="2em" />
                        </li>
                    </ul>
                </section>
            </div>
            {userData && userData?.firstname ? (
                <div className={styles.mobileNav}>
                    <ul className={styles.mobileNavUl}>
                        <li className={styles.mobileNavLi} onClick={() => router.push('/')}>
                            <div className={styles.iconDiv}>
                                <AiOutlineHome className={styles.navIcon} />
                            </div>
                            <p className={styles.mobileNavTitle}>Home</p>
                        </li>
                        <li
                            className={styles.mobileNavLi}
                            onClick={() => router.push('/bet-history')}
                        >
                            <div className={styles.iconDiv}>
                                <MdLibraryBooks className={styles.navIcon} />
                            </div>
                            <p className={styles.mobileNavTitle}>Bet History</p>
                        </li>
                        <li
                            className={styles.mobileNavLi}
                            onClick={() => router.push('transaction-history')}
                        >
                            <div className={styles.iconDiv}>
                                <IoLibraryOutline className={styles.navIcon} />
                            </div>
                            <p className={styles.mobileNavTitle}>Transactions</p>
                        </li>
                        <li className={styles.mobileNavLi} onClick={() => router.push('/deposit')}>
                            <div className={styles.iconDiv}>
                                <IoArrowRedoSharp
                                    className={`${styles.navIcon} ${styles.depositIcon} `}
                                />
                            </div>
                            <p className={styles.mobileNavTitle}>Deposit</p>
                        </li>

                        <li
                            className={styles.mobileNavLi}
                            role="button"
                            onClick={() => setOpenAccountDrawer(true)}
                        >
                            <div className={styles.iconDiv}>
                                <MdAccountCircle className={styles.navIcon} />
                            </div>
                            <p className={styles.mobileNavTitle}>Account</p>
                        </li>
                    </ul>
                </div>
            ) : null}
        </footer>
    );
}
